



import { Component, Vue } from 'vue-property-decorator'
import { loadMicroApp } from 'qiankun'
import { RouteMeta } from 'vue-router/types/router'
import { micorApps } from '@/utils/tools'

@Component({
  name: 'radarBoard'
})
export default class extends Vue {
  mountMicroApp: null | { unmount: Function } = null
  micorApp = micorApps[0]
  created() {
    this.micorApp.props.routerBase = `/amc${
      (this.$route.meta as RouteMeta).routerBase
    }`
    console.log(
      'this.micorApp.props.routerBase',
      this.micorApp.props.routerBase
    )
  }

  mounted() {
    if (this.$refs.radarBoard) {
      try {
        this.mountMicroApp = loadMicroApp(this.micorApp)
      } catch (e) {
        console.log('load radarBoard err', e)
      }
    }

    console.log('this.mountMicroApp', this.micorApp)
  }

  beforeDestroy() {
    if (this.mountMicroApp) {
      this.mountMicroApp.unmount()
    }
  }
}
